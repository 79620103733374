import React from "react"
import Box from "@material-ui/core/Box"
import PageStatus from "@ecom/ui/components/PageStatus"
import { Layout } from "../components/Layout"
import { SEOProps } from "../components/SEO"

type PageReqStatusProps = {
  seoData?: SEOProps
  pageStatus?: {
    success: boolean
    content?: JSX.Element
    title?: string
    description?: string
  }
}

export default function PageReqStatus({ pageContext }: { pageContext: PageReqStatusProps }) {
  const { seoData, pageStatus } = pageContext
  return (
    <Layout seoData={seoData}>
      <Box mt={6} mb={6} flexGrow={1} display="flex" alignItems="center">
        <PageStatus {...pageStatus} />
      </Box>
    </Layout>
  )
}
